import React, { useEffect, useState } from 'react';
import axios from 'axios';

export function GetLeadresBoardData (num) {
  
  const chunk = require(`lodash/chunk`)
	const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.miltonmarkets.com/fxbo/leaders/', {
        // const response = await axios.get('http://13.40.249.23/fxbo/leaders/', {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        let setArray = response.data;
        setArray.shift(); // 先頭データ削除
        setArray = chunk(setArray, num)
        setData(setArray);
      } catch (error) {
        let setArray =  [
          {
            "time": "0:00:00.550997",
            "contestId": ",26"
          },
          {
            "login": 111111,
            "country": "JP",
            "performance": 100,
            "nickname": "aaaaa"
          },
          {
            "login": 222222,
            "country": "JP",
            "performance": 100,
            "nickname": "bbbbb"
          },
          {
            "login": 333333,
            "country": "JP",
            "performance": 100,
            "nickname": "ccccc"
          },
          {
            "login": 444444,
            "country": "JP",
            "performance": 100,
            "nickname": "ddddd"
          },
          {
            "login": 555555,
            "country": "JP",
            "performance": 100,
            "nickname": "eeeee"
          },
          {
            "login": 666666,
            "country": "JP",
            "performance": 100,
            "nickname": "fffff"
          },
          {
            "login": 777777,
            "country": "JP",
            "performance": 100,
            "nickname": "ggggg"
          },
          {
            "login": 888888,
            "country": "JP",
            "performance": 100,
            "nickname": "hhhhh"
          },
        ]
        setArray.shift(); // 先頭データ削除
        setArray = chunk(setArray, num)
        setData(setArray);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return null;
  } else {   
    return data
  } 
};